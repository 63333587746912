<script>
import { Common } from '@/api'
import { mapStores } from 'pinia'
import { useDispParamStore } from '@/stores/dispParam'

import VueBarcode from '@chenfengyuan/vue-barcode';
import Modal from "@/components/basics/SimpleModal.vue";

import { nextTick } from 'vue';

export default {
  name: "PageIndex",
  components: {
    "vue-barcode": VueBarcode,
    Modal,
  },
  data() {
    return {
      opPoint: '',
      gid: '',
      couponParam: {
        v: '',
        iv: '',
        url: ''
      },
      // windowReference: null,
      modalMessage: "よろしいですか？",
      modal: false,
      form: {
        EncGid: '',
        EncryptedPontaId: ''
      },
      landingPage: process.env.VUE_APP_LANDING_PAGE,    //'https://st.ponta.jp/sp/dpc/op/',
      termsPage: process.env.VUE_APP_TERMS_PAGE,      //'https://st.ponta.jp/policy_test/openpoint/index.htm'
      htmlContent: '',
    }
  },
  computed: {
    ...mapStores(useDispParamStore),
  },
  methods: {
    gotoUseBarcode() {
      this.$router.push('/op/ob/fe/barcode')
    },
    gotoExchangePoint() {
      this.$router.push('/op/ob/fe/exchange')
    },
    clearStore() {
      this.dispParamStore.opExchangePoint = '';
      this.dispParamStore.pontaAwardPoint = '';
      this.dispParamStore.opPoint = '';
      this.dispParamStore.pontaPoint = '';
    },
    async gotoCouponPage() {
      // let windowReference = window.open('', 'coupon_page');
      // window.open('', 'coupon_page');

      await this.getCouponInfo();

      // // this.$nextTick(() => this.$refs.coupon_form.submit());
      await nextTick();
      // windowReference.location.href = this.couponParam.url;
      this.$refs.coupon_form.submit();
    },
    async getCouponInfo () {
      const result = await Common.getCouponParam(this.form);
      // console.log('getCoupon-result:', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '900':
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }

      this.couponParam.v = result.V;
      this.couponParam.iv = result.Iv;
      this.couponParam.url = result.Url;
    },
    async setLabel () {
      this.gid = this.dispParamStore.gid
      this.form.EncGid = this.dispParamStore.encGid;
      this.form.EncryptedPontaId = this.dispParamStore.encryptedPontaId;
      if (!this.form.EncGid) {
        this.$router.push('/login');
      }

      // await this.getCouponInfo();

      const result = await Common.menuInit(this.form);
      // console.log('result:', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '900':
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }
      this.opPoint = result.OpPoint;

    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    executeMethod(yes) {
      // モーダルを非表示にして、モーダルでの選択結果によって処理を変える
      this.modal = false;
      if (yes) {
        // alert("はい が押されました。");
      } else {
        // alert("いいえ が押されました。");
      }
    },
    getCommaFormatNum(num) {
      if (num === '') {
        return num;
      }
      if (!isNaN(num)) {
        return Number(num).toLocaleString();
      }
      else {
        return num;
      }
    },
    fetchHTMLFile() {
      fetch("/include/include.html")
      .then(res => {
        if (res.ok) {
          res.text().then(html => {
            this.htmlContent = html;
          });
        }
      });
    }
  },
  created: async function () {
    this.clearStore();
    this.fetchHTMLFile();
    await this.setLabel();
  },
}
</script>

<template>
	<div id="contents" class="member">
		<div class="inner ly-block">
			<h2 class="page-ttl">OPEN POINT会員証</h2>
			<section class="main-box">
				<div class="main-box_line">
					<h3 class="logo"><img src="../assets/img/logo_openpoint.png" alt="OPENPOINT"></h3>
					<div class="point">
						<div class="point_icon"><img src="../assets/img/icon_p_openpoint01.png" alt="Point"></div>
						<p class="point_num" id="jsi-pointNum">{{ getCommaFormatNum(this.opPoint) }}</p>
					</div>
          <div class="barcode">
            <div class="barcode_pic" id="jsi-barcodePic">
              <vue-barcode :value="gid" tag="img" :options="{ format: 'CODE128B', displayValue: false, width: 2.3693, height: 120, margin: 0}"></vue-barcode>
            </div>
						<p class="barcode_num" id="jsi-barcodeNum">{{ gid }}</p>
					</div>
					<div class="ponta_pic"><img src="../assets/img/ponta_01.png" alt="ponta"></div>
				</div>
				<p class="note-txt">※バーコードを読み取る際は、端末の設定から画面の明るさを最大限にしてください。</p>
        <p class="note-txt">※お会計に並ぶ前にOPEN POINT会員証をスマホ画面で表示しておくようお願いいたします。</p>
        <p class="note-txt">※必要な場合お会計時に店員からレシート（統一発票  トン イ ファ ピアオ）を頂いてください。</p>
			</section>

      <div v-html="htmlContent"></div>
			<div class="btn_wrap">
				<ul class=btn_list>
					<li>
            <router-link class="btn btn-p-open" to="/op/ob/fe/barcode">台湾セブンイレブンで<br>OPEN POINTをつかう</router-link>
						<p>OPEN POINT利用可能店舗は<a :href="landingPage">こちら</a></p>
					</li>
          <router-link class="btn btn-p-ponta" to="/op/ob/fe/exchange">Pontaポイントへ交換する</router-link>
          <form id="form" method="post" ref="coupon_form" :action="this.couponParam.url">
            <input type="hidden" name="v" v-model="this.couponParam.v">
            <input type="hidden" name="iv" v-model="this.couponParam.iv">
            <button type="button" class="btn" id="get_coupon" @click="gotoCouponPage">OPEN POINTクーポンを受け取る</button>
          </form>
				</ul>
        <br/>
        <p align="center"><a class="terms_link" :href="termsPage">Ponta会員向けOPEN POINTサービス 利用規約</a></p>
			</div>
		</div>
	</div>

  <div class="modal">
    <modal
      :message="modalMessage"
      v-show="modal"
      @execute-method="executeMethod"
    ></modal>
  </div>
</template>

<style>
.terms_link {
  color: #888888;
}
</style>
